<template>
  <div class="w-full flex flex-col gap-2">
    <div
      v-if="label || hasErrors"
      class="flex flex-row items-center justify-between gap-1.5"
    >
      <label v-if="label" :class="{ error: hasErrors }">{{ label }}</label>
      <div v-if="hasErrors" class="text-red-600">
        <span v-for="error in errors" :key="error">{{ error }}</span>
      </div>
    </div>

    <input
      v-bind="{ ...$props, ...$attrs }"
      @input="model = $event.target.value"
      class="input"
      :class="{ error: hasErrors }"
      :placeholder="placeholder"
      :type="type"
      :value="model"
    />
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps({
    errors: {
      type: Array,
      default: [],
    },

    label: {
      type: String,
      default: null,
    },

    modelValue: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: "text",
    },
  })

  const emit = defineEmits(["update:modelValue"])

  const model = computed({
    get() {
      return props.modelValue // Return the current value
    },

    set(value: string) {
      emit("update:modelValue", value) // Notify parent component of update
    },
  })

  const hasErrors = computed(() => {
    return props.errors.length > 0
  })
</script>

<style lang="scss" scoped>
  .input {
    @apply outline-none rounded-lg border-2 border-gray-200 bg-transparent px-3.5 py-3 dark:border-gray-800;

    &.error {
      @apply border-red-500;
    }
  }
</style>
